.nav-link {
   font-size: 17px;
   color: #1b1a1a;
   font-weight: 600;
  }
  .nav-link:hover {
    color: #FFB6C1 !important;
  }
  .nav-item {
    padding-right: 8px;
  }

  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1020;
    background-color: #ffffff;
  }
  
.fadeInUp {
    animation: fadeInUp 1.2s ease forwards;
  }
  
  .fadeInRightSlow {
    animation: fadeInRightSlow 1.2s ease forwards;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInRightSlow {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
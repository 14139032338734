.features-item {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .features-item.visible {
    opacity: 1;
  }
   
  @media only screen and (max-width: 578px) {
    .col-sm{
      padding: 50px;
    }
  }
@media only screen and (max-width: 768px) {
  .sliderText {
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
  img {
    width: 100%;
  }
  .maintext {
    display: none;
  }
  .slidebuttons {
    display: none;
  }
  .headtext span{
    float: left;
    font-size: 16px;    
    width: 100%;
    margin-top: -130px;
    margin-left: -110px;
  }
  .ch-btn-style-2{
    display: none;
  }
}
